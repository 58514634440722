<template>
  <super-admin-page-card
    pageName="Manage Navigator Reports"
    iconClass="fa fa-th fa-lg"
  >
    <div>
      <b-tabs v-model="tabIndex">
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">Manage Report Access</h6>
          </template>

          <v-wait for="loadingNavigatorReports">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>

            <b-row class="justify-content-end mb-4">
              <b-col sm="4">
                <b-form-group label="Search" :label-cols="3" :horizontal="true">
                  <b-form-input v-model="searchText"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              striped
              hover
              :fields="tableFields"
              :filter="searchText"
              :items="navigatorReports"
              small
              responsive
            >
              <template v-slot:cell(is_public)="data">
                <c-switch
                  type="text"
                  variant="success"
                  on="Yes"
                  off="No"
                  :pill="true"
                  size="sm"
                  :checked="data.item.is_public"
                  :disabled="data.item.fi_type === 'bhc'"
                  @change="updateAccess(data.item, $event)"
                  :class="{ 'disabled-switch': data.item.fi_type === 'bhc' }"
                />
              </template>
              <template v-slot:cell(category)="data">
                <c-switch
                  type="text"
                  variant="success"
                  on="Yes"
                  off="No"
                  :pill="true"
                  size="sm"
                  :checked="data.item.category === 'fin_reports'"
                  @change="updateCategory(data.item, $event)"
                />
              </template>
            </b-table>
          </v-wait>
        </b-tab>
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">
              Manage Industry Report Order
            </h6>
          </template>

          <v-wait for="loadingNavigatorReports">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>
            <div class="row">
              <div class="col-sm-6">
                <draggable
                  class="list-group"
                  tag="ul"
                  v-model="publicReports"
                  @change="saveCardPosition"
                  @start="dragStart"
                  @end="dragEnd"
                  :forceFallback="true"
                  v-if="publicReports.length"
                >
                  <div
                    v-for="(report, index) in publicReports"
                    class="list-group-item cursor-pointer"
                    :key="report.id"
                  >
                    {{ report.name }}
                    <div class="d-inline float-right index-icon px-2">
                      {{ index + 1 }}
                    </div>
                  </div>
                </draggable>
                <div v-else>
                  <div class="list-group">
                    <div class="list-group-item p-4">
                      No Public Reports to sort
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-wait>
        </b-tab>
      </b-tabs>
    </div>
  </super-admin-page-card>
</template>

<script>
// ui components
import cSwitch from '@/components/Switch'
import draggable from 'vuedraggable'
// api
import selfServeReportsAPI from '@/api/finapps/super_admin/self_serve_reports'
// utilities
import _ from 'lodash'

export default {
  name: 'SuperAdminNavigatorReports',
  components: {
    cSwitch,
    draggable
  },
  data() {
    return {
      tabIndex: 0,
      searchText: null,
      navigatorReports: [],
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Report Name', sortable: true },
        { key: 'fi_type', label: 'FI Type', sortable: true },
        { key: 'creator', label: 'Created By', sortable: true },
        { key: 'is_public', label: 'Industry Reporter' },
        { key: 'category', label: 'FIN Reporter' }
      ]
    }
  },
  created() {
    this.loadInitData()
  },
  computed: {
    publicReports: {
      get() {
        let publicReports = this.filteredNavigatorReports.filter(
          nr => nr.is_public
        )
        return _.sortBy(publicReports, ['report_order', 'name'])
      },
      set(sortedReports) {
        sortedReports.forEach((report, index) => {
          report.report_order = index + 1
        })
      }
    },
    filteredNavigatorReports() {
      return this.navigatorReports.filter(nr => nr.fi_type !== 'bhc')
    }
  },
  methods: {
    loadInitData() {
      this.loadNavigatorReports()
    },
    loadNavigatorReports() {
      this.$wait.start('loadingNavigatorReports')

      selfServeReportsAPI.reports().then(res => {
        this.navigatorReports = res.navigator_reports
        this.$wait.end('loadingNavigatorReports')
      })
    },

    updateAccess(report, state) {
      selfServeReportsAPI.updateAccess(report.id, state).then(res => {
        report.is_public = state
        report.report_order = res.report.report_order
        this.$toasted.global.action_success(
          'Report access successfully modified.'
        )
      })
    },
    updateCategory(report, state) {
      let category = state ? 'fin_reports' : null

      selfServeReportsAPI.updateCategory(report.id, category).then(
        () => {
          this.$toasted.global.action_success(
            'Report changes successfully updated.'
          )
        },
        () => {}
      )
    },
    saveCardPosition() {
      selfServeReportsAPI.updateOrder(this.publicReports).then(() => {
        this.$toasted.global.action_success(
          'Report Order successfully updated.'
        )
      })
    },

    dragStart() {},
    dragEnd() {}
  }
}
</script>
<style lang="scss" scoped>
.index-icon {
  border-radius: 50%;
  font-weight: bold;
  color: white;
  background-color: #7b7878;
}
.disabled-switch {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
